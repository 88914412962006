import React from 'react';
import { Box, Card, Chip, Grid, Typography } from '@mui/material';
import Image from 'next/image';

import { NotStyledParser } from '@/shared/ui';
import { IImage } from '@/shared/types';
import { TBlockRequirementsV2 } from '@/shared/api/homepage-api';

interface RequirementsCardLargeProps {
  icon: IImage;
  title: string;
  background: IImage;
  backgroundMobile: IImage;
  chips: TBlockRequirementsV2['cardLargeChips'];
  chipsMobile: TBlockRequirementsV2['cardLargeChipsMobile'];
}

export const RequirementsCardLarge = (props: RequirementsCardLargeProps) => {
  const { icon, title, background, backgroundMobile, chips, chipsMobile } =
    props;

  const chipItem = (label: string) => {
    return (
      <Box
        mr={1}
        mb={{
          xs: 1,
          md: 0,
        }}
      >
        <Chip label={label} />
      </Box>
    );
  };

  return (
    <Card
      variant="animated"
      sx={{
        width: {
          xs: 285,
          md: 'auto',
        },
        height: {
          xs: 320,
          md: '100%',
        },
        position: 'relative',
        backgroundImage: {
          xs: `url(${backgroundMobile?.data?.attributes?.url})`,
          md: 'none',
        },
        backgroundSize: 'cover',
      }}
    >
      <Grid container flexWrap={{ xs: 'wrap', md: 'nowrap' }}>
        <Grid item xs={12} md="auto">
          <Box sx={{ p: 3.75, position: 'relative', height: '100%' }}>
            <Image
              src={icon?.data?.attributes.url}
              alt={icon?.data?.attributes.alternativeText || ''}
              width={35}
              height={35}
            />
            <Box
              mt={2.5}
              mb={{
                xs: 3,
                md: 0,
              }}
              sx={{
                width: 130,
              }}
            >
              <Typography variant="headline6" component="div">
                <NotStyledParser data={title} />
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md
          flexGrow={2}
          display={{
            xs: 'none',
            md: 'flex',
          }}
        >
          <Image
            src={background?.data?.attributes.url}
            alt={background?.data?.attributes.alternativeText || ''}
            width={background?.data?.attributes.width}
            height={background?.data?.attributes.height}
            style={{
              height: 'auto',
            }}
          />
        </Grid>
      </Grid>
      <Box
        display={{
          xs: 'none',
          md: 'flex',
        }}
        sx={{
          position: 'absolute',
          bottom: {
            xs: 20,
            md: 30,
          },
          left: 30,
        }}
      >
        {chips?.map((item) => (
          <React.Fragment key={item.id}>{chipItem(item.label)}</React.Fragment>
        ))}
      </Box>
      <Box
        display={{
          xs: 'flex',
          md: 'none',
        }}
        sx={{
          position: 'absolute',
          bottom: {
            xs: 20,
            md: 30,
          },
          left: 30,
        }}
      >
        {chipsMobile?.map((item) => (
          <React.Fragment key={item.id}>{chipItem(item.label)}</React.Fragment>
        ))}
      </Box>
    </Card>
  );
};
