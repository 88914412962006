import React from 'react';
import { Container, Paper, useMediaQuery, useTheme } from '@mui/material';
import { useConfig } from '@/shared/hooks/use-config';
import { MobileAppBanner, MobileAppBannerProps } from '@/shared/ui';

type MobileAppBannerOuterProps = Omit<MobileAppBannerProps, 'orientation'>;

export const MobileAppBannerOuter = (props: MobileAppBannerOuterProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const appConfig = useConfig();

  return (
    <Container>
      <Paper
        elevation={0}
        sx={{
          borderRadius: 2,
          marginBottom: {
            xs: 3,
            md: 6,
            lg: 8,
          },
          bgcolor: 'neutral.95',
          color: 'common.onPrimaryContainer',
          boxShadow: {
            md: 'none',
          },
        }}
      >
        <MobileAppBanner
          orientation={isMobile ? 'vertical' : 'horizontal'}
          title={props.title}
          imageUrl={props.imageUrl}
          features={props.features}
          imageAlt={props.imageAlt}
          googlePlayLink={appConfig?.externalLinks.googlePlayBadge}
        />
      </Paper>
    </Container>
  );
};
