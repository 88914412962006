export type TConfigKey = `${number}` | 'default';
export type TConfig = {
  unit?: 'rem' | 'em' | 'px' | '%';
  breakpoints: Record<TConfigKey, number>;
};

export const useDynamicFontSize = (config?: TConfig) => {
  const _config: TConfig = {
    unit: 'rem',
    breakpoints: {
      default: 1.375,
      '9': 1.2,
      '12': 1,
      '15': 0.875,
      '20': 0.625,
      ...config?.breakpoints,
    },
    ...config,
  };

  return (value: string) => {
    const valueLength = value.length;
    let maxBreakpoint = 0;
    let fontSize = _config.breakpoints.default;

    (
      Object.keys(_config.breakpoints) as Array<
        keyof typeof _config.breakpoints
      >
    ).forEach((value: TConfigKey) => {
      const numberKey = Number(value);
      if (Number.isInteger(numberKey)) {
        if (valueLength > numberKey) {
          if (!numberKey || Number(maxBreakpoint) < numberKey) {
            maxBreakpoint = numberKey;
            fontSize = _config.breakpoints[value];
          }
        }
      }
    });

    return `${fontSize}${_config.unit}`;
  };
};
