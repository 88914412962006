import React from 'react';
import { Box, Container } from '@mui/material';

import { TBlockVideoFeedback } from '@/shared/api/homepage-api';
import { HorizontalSlider, ResponsiveTypography } from '@/shared/ui';

type BlockVideoFeedbackProps = Pick<
  TBlockVideoFeedback,
  'title' | 'youtubeVideo'
>;

export const BlockVideoFeedback = (props: BlockVideoFeedbackProps) => {
  const { title, youtubeVideo } = props;

  const feedbackDesktop = (link: string) => (
    <Box
      sx={{
        overflow: 'hidden',
        borderRadius: 2.5,
        display: 'flex',
        '& > iframe': {
          width: {
            xs: 288,
            md: 386,
          },
        },
      }}
    >
      <iframe
        width="386"
        height="220"
        src={link}
        style={{
          border: 'none',
        }}
      />
    </Box>
  );

  const videoItems = youtubeVideo?.map((item) => feedbackDesktop(item.link));

  return (
    <Box
      my={{
        xs: 7.5,
        md: 10,
      }}
    >
      <Container>
        {title ? (
          <ResponsiveTypography
            variantMap={{
              xs: 'headline5',
              md: 'headline3',
            }}
            component="h3"
            textAlign={{
              md: 'center',
            }}
          >
            {title}
          </ResponsiveTypography>
        ) : null}

        <Box
          mt={{
            xs: 2.5,
            md: 5,
          }}
          sx={{
            position: 'relative',
            height: 220,
          }}
        >
          {videoItems ? (
            <HorizontalSlider
              position={{
                md: 'absolute',
              }}
              width={{
                md: 'calc((100vw - 1200px) / 2 + 1180px)',
              }}
              spacing={2}
              items={videoItems}
            />
          ) : null}
        </Box>
      </Container>
    </Box>
  );
};
