import React from 'react';
import { Box, Card, Grid } from '@mui/material';
import { StepCardLeft } from '@/components/home/block-steps-v2/cards/step-card-left';
import Image from 'next/image';
import { TBlockStepsStepOutlineBg } from '@/shared/api/homepage-api';

type StepCardOutlineBgProps = Pick<
  TBlockStepsStepOutlineBg,
  'stepNumber' | 'title' | 'imageMobile' | 'imageDesktop'
>;

export const StepCardOutlineBg = (props: StepCardOutlineBgProps) => {
  const { stepNumber, title, imageMobile, imageDesktop } = props;

  return (
    <Card
      variant="animated"
      sx={{
        p: 3.75,
        height: '100%',
        width: {
          xs: 285,
          md: 'auto',
        },
      }}
    >
      <Grid container spacing={2} flexWrap={{ md: 'nowrap' }}>
        <Grid item xs>
          <StepCardLeft stepNumber={stepNumber} title={title} />
        </Grid>
        <Grid item xs={12} md="auto">
          <Box
            mr={-3.75}
            display={{
              xs: 'none',
              md: 'flex',
            }}
          >
            <Image
              src={imageDesktop?.data.attributes.url}
              alt={imageDesktop?.data.attributes.alternativeText || ''}
              width={imageDesktop?.data.attributes.width}
              height={imageDesktop?.data.attributes.height}
              style={{
                objectFit: 'contain',
              }}
            />
          </Box>
          <Box display={{ md: 'none' }} mr={-3.75} mb={-4.5} mt={0.5}>
            <Image
              src={imageMobile?.data.attributes.url}
              alt={imageMobile?.data.attributes.alternativeText || ''}
              width={imageMobile?.data.attributes.width}
              height={imageMobile?.data.attributes.height}
              style={{
                objectFit: 'contain',
                height: 'auto',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
