import {
  Box,
  Button,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { SectionOuter } from '@/shared/ui';
import { IImage } from '@/shared/types';

interface IHomeBlockMainProps {
  title: string;
  subtitle: string;
  text: string;
  buttonText: string;
  image_mobile: IImage;
  image_desktop: IImage;
  onClick: () => void;
}

export const HomeBlockMain = (props: IHomeBlockMainProps) => {
  const {
    title,
    subtitle,
    text,
    buttonText,
    image_desktop,
    image_mobile,
    onClick,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        bgcolor: {
          xs: theme.palette.common.white,
          sm: 'transparent',
        },
        pb: {
          xs: 4,
          sm: 0,
        },
      }}
    >
      <SectionOuter
        disableGutters={isMobile}
        sx={{
          pt: {
            xs: '72px',
            sm: 0,
          },
          mt: { xs: '-72px', sm: 0 },
          border: `1px solid ${theme.palette.neutral[80]}`,
          bgcolor: theme.palette.common.white,
          backgroundImage: {
            xs: `url(${image_mobile?.data?.attributes?.url})`,
            md: `url(${image_desktop?.data?.attributes?.url})`,
          },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: {
            xs: 'bottom right',
            md: '130% center',
            lg: '100% center',
          },
          backgroundSize: {
            xs: 'cover',
            md: 'contain',
          },
          [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
            marginBottom: 0,
          },
        }}
      >
        <Grid container>
          <Grid item md={6} lg={6}>
            <Box mb={{ xs: 2, md: 3 }}>
              <Box
                display={{
                  xs: 'none',
                  md: 'block',
                }}
              >
                <Typography
                  variant="headline1"
                  fontWeight={700}
                  color="common.onPrimaryContainer"
                >
                  {title}
                </Typography>
              </Box>
              <Box
                display={{
                  xs: 'block',
                  md: 'none',
                }}
              >
                <Typography
                  variant="headline2"
                  fontWeight={700}
                  color="common.onPrimaryContainer"
                >
                  {title}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item md={6} lg={3}>
            <Box mb={{ xs: 3 }}>
              <Typography variant="headline4" color="common.onPrimaryContainer">
                {subtitle}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item md={6} lg={6}>
            <Box mb={3}>
              <Typography variant="text4" color="common.onPrimaryContainer">
                {text}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Button onClick={onClick} fullWidth={isMobile} size="large">
                {buttonText}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </SectionOuter>
    </Box>
  );
};
