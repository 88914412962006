import { ApiInstance } from '@/shared/api/instance';
import { IImage, TSEOComponentData } from '@/shared/types';

export enum GrievanceComponentType {
  Card = 'grievance.grievance-card',
  CardWithImage = 'grievance.grievance-card-with-image',
}

export type GrievanceCardWithImage = {
  id: number;
  badge?: IImage;
  imageDesktop?: IImage;
  imageMobile?: IImage;
  __component: GrievanceComponentType.CardWithImage;
};
export type GrievanceCard = {
  id: number;
  title: string;
  cardRow: { id: number; name: string; value: string }[];
  __component: GrievanceComponentType.Card;
};

export type GrievanceCardVariants = GrievanceCard | GrievanceCardWithImage;

export type GrievanceContent = {
  title: string;
  cards: Array<GrievanceCardVariants>;
  seo?: TSEOComponentData;
};

export class GrievanceApi extends ApiInstance {
  constructor(locale?: string) {
    super(locale);
  }

  async getGrievanceData(): Promise<GrievanceContent> {
    const query = {
      populate: [
        'seo',
        'seo.metaSocial',
        'seo.metaSocial.image',
        'seo.metaImage',
        'cards',
        'cards.cardRow',
        'cards.imageDesktop',
        'cards.imageMobile',
        'cards.badge',
        'seo',
        'seo.metaSocial',
        'seo.metaSocial.image',
        'seo.metaImage',
      ],
    };

    return this._SendWithQuery('/grievance', query).then(
      (res: any) => res?.attributes || {},
    );
  }
}
