import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Image from 'next/image';
import { TBlockAmmanaStats } from '@/shared/api/homepage-api';
import {
  NotStyledParser,
  ResponsiveTypography,
  SectionContainer,
} from '@/shared/ui';
import { AmmanaApi } from '@/shared/api';

type AmmanaStatsProps = Pick<TBlockAmmanaStats, 'title' | 'numbers'>;

export const AmmanaStats = (props: AmmanaStatsProps) => {
  const { title, numbers } = props;

  const transformNumber = (
    number: number,
    fraction?: any,
    full?: boolean,
  ): string | null => {
    if (number === null) return null;
    if (number === 0) return '0';

    if (typeof fraction !== 'number' || fraction < 0) fraction = 2;

    let abs = Math.abs(number);
    const isNegative = number < 0;
    let key = '';
    const powers = [
      { key: 'Q', name: 'Q', value: Math.pow(10, 15) },
      { key: 'T', name: 'T', value: Math.pow(10, 12) },
      { key: 'M', name: 'M', value: Math.pow(10, 9) },
      { key: 'jt', name: 'JT', value: Math.pow(10, 6) },
      { key: 'rb', name: 'RB', value: 1000 },
    ];

    for (let i = 0; i < powers.length; i++) {
      const reduced = abs / powers[i].value;
      if (reduced >= 1) {
        abs = reduced;
        key = full ? powers[i].name : powers[i].key;
        break;
      }
    }

    if (abs === 0) {
      fraction = 0;
    }

    return (
      (isNegative ? '-' : '') +
      abs.toFixed(fraction).replace('.', ',') +
      ' ' +
      key
    );
  };

  const [statsInfo, setStatsInfo] = useState(numbers);

  useEffect(() => {
    const getData = async () => {
      const api = new AmmanaApi();
      const response = await api.getStatistic();

      const mapping: any = {
        'total_customer': response.total_customer,
        'disbursed_campaigns_this_year': response.disbursed_campaign?.this_year
          ? transformNumber(response.disbursed_campaign?.this_year, 0, true)
          : 0,
        'disbursed_campaigns_only_this_year': response.disbursed_campaign?.only_this_year
          ? transformNumber(
            response.disbursed_campaign?.only_this_year,
            0,
            true,
          )
          : 0,
        'tkb90': response.tkb90,
      };

      if (numbers) {
        setStatsInfo(
          numbers.map((item: any, index: number) => {
            if (typeof mapping[item.key] !== 'undefined') {
              const detail = numbers[index];
              detail.value = mapping[item.key];

              return detail;
            }

            return item;
          }) as any,
        );
      }
    };

    getData();
  }, []);

  return (
    <SectionContainer>
      <Box
        sx={(theme) => ({
          borderTop: {
            xs: `none`,
            md: `1px solid ${theme.palette.neutral[90]}}`,
          },
        })}
      >
        <Box
          sx={(theme) => ({
            pt: {
              xs: 0,
              md: 5,
            },
            pb: {
              xs: 3.5,
              md: 5,
            },
            borderBottom: `1px solid ${theme.palette.neutral[90]}}`,
          })}
        >
          <Grid
            container
            spacing={{ xs: 0, md: 4 }}
            direction={{
              xs: 'column',
              md: 'row',
            }}
          >
            {title ? (
              <Grid item xs={12} md={4}>
                <Box
                  ml={{
                    md: 2,
                    lg: 4,
                  }}
                >
                  <ResponsiveTypography
                    component="div"
                    variantMap={{
                      xs: 'headline5',
                      md: 'headline3',
                    }}
                    sx={{
                      '& > *': {
                        whiteSpace: {
                          xs: 'nowrap',
                          md: 'wrap',
                        },
                        display: {
                          md: 'block',
                        },
                      },
                    }}
                  >
                    <NotStyledParser data={title} />
                  </ResponsiveTypography>
                </Box>
              </Grid>
            ) : null}
            <Grid container item xs={12} md={8}>
              {statsInfo?.map((item, index) => (
                <Grid
                  key={item.id}
                  item
                  xs={12}
                  md={4}
                  sx={{
                    mr: 'auto',
                    display: {
                      xs: 'flex',
                      md: 'block',
                    },
                    justifyItems: 'start',
                    pt: {
                      xs: 3.5,
                      md: 0,
                    },
                    pb: {
                      md: 3,
                    },
                  }}
                >
                  {item.image?.data ? (
                    <Box
                      sx={{
                        flex: {
                          xs: '0 0 70px',
                          md: '0 0 118px',
                        },
                        mr: {
                          xs: 2.5,
                          md: 0,
                        },
                        ml: {
                          xs: index % 2 ? 11.25 : 0,
                          md: 0,
                        },
                        mb: {
                          md: 2.5,
                        },
                      }}
                    >
                      <Image
                        src={item.image?.data.attributes.url}
                        alt={item.image?.data.attributes.alternativeText || ''}
                        width={item.image?.data.attributes.width}
                        height={item.image?.data.attributes.height}
                        style={{
                          height: 'auto',
                        }}
                      />
                    </Box>
                  ) : null}

                  <Box
                    display={{
                      xs: 'block',
                      md: 'none',
                    }}
                  >
                    <Typography variant="headline5" color="neutral.20">
                      {item.value}
                    </Typography>
                    <Typography variant="text6" color="neutral.50">
                      {item.valueDescription}
                    </Typography>
                  </Box>
                  <Box
                    display={{
                      xs: 'none',
                      md: 'block',
                    }}
                  >
                    <Typography
                      variant="headline1"
                      color="neutral.20"
                      sx={{
                        mb: 1.25,
                      }}
                    >
                      {item.value}
                    </Typography>
                    <Typography variant="text4" color="neutral.50">
                      {item.valueDescription}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </SectionContainer>
  );
};
