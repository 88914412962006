import React from 'react';
import Image from 'next/image';
import { Box, Card, Container, Grid, useTheme } from '@mui/material';
import { NotStyledParser, ResponsiveTypography } from '@/shared/ui';
import { CreditLimitSliderItem } from '@/shared/api/homepage-api';
import { Form } from '@/components/home/credit-limit/ui/form';

type BlockCreditLimitCardProps = Pick<
  CreditLimitSliderItem,
  | 'buttonText'
  | 'description'
  | 'title'
  | 'phoneNumberLabel'
  | 'imageDesktop'
  | 'imageMobile'
>;

export const BlockCreditLimitCard = (props: BlockCreditLimitCardProps) => {
  const theme = useTheme();

  const sectionStyles = {
    mt: { xs: -9, sm: 0 },
    bgcolor: theme.palette.primary[95],
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      bgcolor: 'transparent',
      borderRadius: 0,
      marginBottom: 0,
    },
  };

  return (
    <Container>
      <Card sx={sectionStyles}>
        <Box
          px={{
            xs: 2,
            md: 7,
          }}
          py={{
            xs: 2,
            md: 8.875,
          }}
        >
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} lg={6}>
              <ResponsiveTypography
                variantMap={{
                  xs: 'headline5',
                  md: 'headline2',
                }}
                color="common.onPrimaryContainer"
                mb={{ xs: 2, md: 3 }}
              >
                <NotStyledParser data={props.title} />
              </ResponsiveTypography>
              <ResponsiveTypography
                variantMap={{
                  xs: 'text6',
                  md: 'text4',
                }}
                color="neutral.50"
                mb={{ xs: 3 }}
                lineHeight={2}
              >
                <NotStyledParser data={props.description} />
              </ResponsiveTypography>
              <Form
                phoneNumberLabel={props.phoneNumberLabel}
                buttonText={props.buttonText}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={5} display="flex" alignItems="center">
              <Image
                src={props.imageDesktop.data?.attributes?.url}
                alt={props.imageDesktop.data?.attributes?.alternativeText || ''}
                width={props.imageDesktop.data?.attributes?.width}
                height={props.imageDesktop.data?.attributes?.height}
                style={{ height: 'auto' }}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Container>
  );
};
