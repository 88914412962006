import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
} from '@mui/material';
import { SectionOuter } from '@/shared/ui';
import React from 'react';
import { HorizontalSlider } from '@/shared/ui/horizontal-slider';
import { IImage } from '@/shared/types';
import Image from 'next/image';

interface IStep {
  id: number;
  title: string;
  description: string;
  image: IImage;
  buttonText: string;
}

interface IHomeBlockStepsProps {
  subtitle: string;
  title: string;
  steps: IStep[];
  onClick: () => void;
}

export const HomeBlockSteps = (props: IHomeBlockStepsProps) => {
  const { subtitle, title, steps, onClick } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const stepsLayout = steps?.map((step, index, array) => {
    const component = (
      <Card
        elevation={0}
        sx={{
          bgcolor: 'neutral.100',
          borderRadius: 2,
          height: '100%',
          [theme.breakpoints.down('sm')]: {
            width: 150,
          },
        }}
      >
        <CardContent
          sx={{
            padding: {
              xs: 2,
              md: 3,
            },
          }}
        >
          <Box
            display="flex"
            alignItems="flex-end"
            mb={{
              xs: 2,
              md: 3,
            }}
            sx={{
              width: {
                xs: 32,
                md: 52,
              },
              height: {
                xs: 32,
                md: 52,
              },
            }}
          >
            <Image
              src={step?.image?.data?.attributes?.url}
              alt={step?.image?.data?.attributes?.alternativeText || step.title}
              width={52}
              height={52}
              style={{
                objectFit: 'contain',
                height: 'auto',
              }}
            />
          </Box>
          <Box
            mb={{
              xs: 0.5,
              md: 3,
            }}
          >
            <Typography
              color="neutral.20"
              variant={isMobile ? 'text7' : 'headline5'}
              fontWeight={600}
            >
              {step.title}
            </Typography>
          </Box>
          {step.description ? (
            <Typography
              variant={isMobile ? 'text8' : 'text6'}
              color="neutral.20"
            >
              {step.description}
            </Typography>
          ) : null}
        </CardContent>
        {step.buttonText && (
          <CardActions
            sx={{
              display: {
                xs: 'none',
                md: 'block',
              },
              padding: {
                md: theme.spacing(1, 3, 3),
              },
            }}
          >
            <Button onClick={onClick} size="small" fullWidth>
              {step.buttonText}
            </Button>
          </CardActions>
        )}
      </Card>
    );

    return (
      <React.Fragment key={step.id}>
        <Box sx={{ display: { xs: 'block', md: 'none' }, height: '100%' }}>
          {component}
        </Box>
        <Grid
          item
          sm={12 / array.length}
          md={6}
          lg={12 / array.length}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          {component}
        </Grid>
      </React.Fragment>
    );
  });

  const stepWithButton = steps.find((step) => !!step.buttonText);

  const content = (
    <>
      {title ? (
        <Box pb={{ xs: 2, md: 3 }}>
          <Typography
            variant={isMobile ? 'headline6' : 'headline3'}
            textAlign={{ md: 'center' }}
            color="common.onPrimaryContainer"
          >
            {title}
          </Typography>
        </Box>
      ) : null}

      {subtitle ? (
        <Box pb={3}>
          <Typography
            variant={isMobile ? 'headline8' : 'headline6'}
            textAlign={{ md: 'center' }}
            color="common.onPrimaryContainer"
          >
            {subtitle}
          </Typography>
        </Box>
      ) : null}
      <Box display={{ xs: 'block', md: 'none' }}>
        <HorizontalSlider spacing={2} items={stepsLayout} />
      </Box>
      <Box display={{ xs: 'none', md: 'block' }}>
        <Grid container spacing={2} alignItems="stretch">
          {stepsLayout}
        </Grid>
      </Box>
      <Box
        mt={2}
        display={{
          md: 'none',
        }}
      >
        <Stack direction="row" justifyContent="center">
          {stepWithButton ? (
            <Button onClick={onClick} fullWidth>
              {stepWithButton.buttonText}
            </Button>
          ) : null}
        </Stack>
      </Box>
    </>
  );

  const sxProps = {
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  };

  return (
    <>
      <Box display={{ xs: 'block', md: 'none' }}>
        <SectionOuter color="secondary.95" sx={sxProps} disableGutters>
          {content}
        </SectionOuter>
      </Box>
      <Box display={{ xs: 'none', md: 'block' }}>
        <SectionOuter color="secondary.95" sx={sxProps}>
          {content}
        </SectionOuter>
      </Box>
    </>
  );
};
