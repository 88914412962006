import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import { InputPhone } from '@/shared/ui';
import { useConfig } from '@/shared/hooks';
import { CreditLimitSliderItem } from '@/shared/api/homepage-api';
import {
  getPhoneInputParentElement,
  isTambadanaMirror,
  redirectTambadanaWhattsapp,
  redirectToAccount,
  useFormatters,
} from '@/utils';
import { useTranslations } from 'use-intl';
import {
  AB_TESTING_REDIRECT_STORAGE_KEY,
  LANDING_REFERRER_STORAGE_KEY,
  LANDING_URL_STORAGE_KEY,
} from '@/shared/constants';
import { clearChangeAmountActions } from '@/entities/first-loan-calculator-v2/analytics';
import { ABTestingBlockNames, setToLocalStorage } from '@/features/ab-testing';
import { useUtmParams } from '@/shared/lib';
import { getOtpCode } from '@/features/otp-code-form';

type FormProps = Pick<CreditLimitSliderItem, 'phoneNumberLabel' | 'buttonText'>;

export const Form: React.FC<FormProps> = (props) => {
  const config = useConfig();

  const t = useTranslations();

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [inputError, setInputError] = useState(false);
  const [errorCode, setErrorCode] = useState<string>();
  const { formatPhoneNumber } = useFormatters();
  const utmParams = useUtmParams();

  const tambadanaMirror = isTambadanaMirror();

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputError(false);
    const phoneInputElementParent = getPhoneInputParentElement();
    if (phoneInputElementParent) {
      phoneInputElementParent.style.boxShadow = '';
    }
    setPhoneNumber(e.currentTarget.value);
  };

  const errorMessageByCode: Record<string, string> = {
    countdown_error: t('errors.phoneNumber.countdown_error'),
  };
  const helperText = errorCode
    ? errorMessageByCode[errorCode] || t('errors.phoneNumber.error')
    : null;

  const setPhoneInputError = () => {
    setInputError(true);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setToLocalStorage(
      AB_TESTING_REDIRECT_STORAGE_KEY,
      ABTestingBlockNames.CreditLine,
    );

    if (tambadanaMirror) {
      redirectTambadanaWhattsapp();
    }

    if (phoneNumber.length < 2) {
      setPhoneInputError();
      return;
    }
    setInputError(false);
    const phoneNumberStorageItemKey = 'landing.phoneNumber';

    sessionStorage.setItem(
      phoneNumberStorageItemKey,
      phoneNumber.split(' ').join(''),
    );

    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

    getOtpCode(config?.apiUrl, formattedPhoneNumber)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setErrorCode(data.error.code);
          setPhoneInputError();
          return;
        }
        clearChangeAmountActions();
        setToLocalStorage(
          AB_TESTING_REDIRECT_STORAGE_KEY,
          ABTestingBlockNames.CreditLine,
        );

        const landingInfo = {
          'landing.phoneNumber': formattedPhoneNumber,
          'landing.referrer': localStorage.getItem(
            LANDING_REFERRER_STORAGE_KEY,
          ),
          'landing.url': localStorage.getItem(LANDING_URL_STORAGE_KEY),
          'landing.abTests': ABTestingBlockNames.CreditLine,
        };

        const landingInfoEncoded = btoa(JSON.stringify(landingInfo));

        const params = new URLSearchParams();

        params.append('authId', data.data.auth_id);
        params.append('sourceFrom', 'landing');
        params.append('landingInfo', landingInfoEncoded);

        for (const key in utmParams) {
          if (utmParams.hasOwnProperty(key)) {
            params.append(key, utmParams[key].toString());
          }
        }

        redirectToAccount(`/auth?${params.toString()}`);
      })
      .catch((error) => {
        setErrorCode('unknown');
        setPhoneInputError();
      });
  };

  return (
    <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
      <Grid item xs={12} md={6}>
        <InputPhone
          placeholder={props.phoneNumberLabel}
          onChange={handlePhoneNumberChange}
          value={phoneNumber}
          error={inputError}
          helperText={helperText}
          sx={{
            '& .MuiInputBase-root': {
              overflow: 'auto',
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Button type="submit" fullWidth size="large">
          {props.buttonText}
        </Button>
      </Grid>
    </Grid>
  );
};
