import { ApiInstance } from './instance';

export interface RepaymentRequisitesRow {
  id: number;
  title: string;
  description: string;
  show_modal: boolean;
}

export interface RepaymentStepComponent {
  id: number;
  __component: 'repayment.step';
  title: string;
  description: string;
}

export interface RepaymentRequisitesComponent {
  id: number;
  __component: 'repayment.requisites';
  title: string;
  description: string;
  row: RepaymentRequisitesRow[];
}

export type ShortDescriptionComponent =
  | RepaymentStepComponent
  | RepaymentRequisitesComponent;

export interface IPaymentGuide {
  id: number;
  attributes: {
    description: string;
    detailed_instruction?: {
      id: number;
      title: string;
      description?: string;
    }[];
    payment_method_category?: {
      data?: IPaymentMethodCategory;
    };
    payment_provider?: {
      data?: IPaymentProvider;
    };
    short_description: {
      id: number;
      title: string;
      description?: string;
    }[];
    title: string;
    short_description_v2?: ShortDescriptionComponent[];
  };
}

export interface IPaymentMethodCategory {
  id: number;
  attributes: {
    processing_type: string;
    title: string;
  };
}

export interface IPaymentProvider {
  id: number;
  attributes: {
    logo?: {
      data?: {
        attributes: {
          url: string;
          alt: string;
        };
      };
    };
    name: string;
    ceyloan_bank_account_number?: string;
    ceyloan_bank_branch_name?: string;
    find_branch_link?: string;
  };
}

export class RepaymentGuideApi extends ApiInstance {
  constructor(locale?: string) {
    super(locale);
  }

  getGuides() {
    const query = {
      populate: {
        payment_provider: {
          populate: ['logo'],
        },
        payment_method_category: '*',
      },
    };

    return this._SendWithQuery<IPaymentGuide[]>('/payment-guides', query);
  }

  getGuide(id: string) {
    const query = {
      populate: {
        payment_provider: {
          populate: ['logo'],
        },
        payment_method_category: '*',
        short_description_v2: {
          populate: ['row'],
        },
        short_description: '*',
        detailed_instruction: '*',
      },
    };

    return this._SendWithQuery<IPaymentGuide>(`/payment-guides/${id}`, query);
  }
}
