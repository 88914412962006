import React from 'react';
import { CreditLimitSliderItem } from '@/shared/api/homepage-api';
import { Box, Typography } from '@mui/material';
import { NotStyledParser } from '@/shared/ui';
import Image from 'next/image';
import { Form } from '@/components/home/credit-limit/ui/form';

type CardMobileProps = Pick<
  CreditLimitSliderItem,
  | 'buttonText'
  | 'description'
  | 'title'
  | 'phoneNumberLabel'
  | 'imageDesktop'
  | 'imageMobile'
>;

export const CardMobile: React.FC<CardMobileProps> = (props) => {
  return (
    <Box mt={2}>
      <Box mx={2}>
        <Typography
          variant="headline5"
          textAlign="center"
          color="neutral.20"
          marginBottom={1}
        >
          <NotStyledParser data={props.title} />
        </Typography>
        <Typography
          variant="text6"
          textAlign="center"
          color="neutral.50"
          marginBottom={3}
        >
          <NotStyledParser data={props.description} />
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        p={2}
        sx={{
          bgcolor: 'primary.95',
          maxHeight: 211,
        }}
      >
        <Image
          src={props.imageMobile.data.attributes.url}
          alt={props.imageMobile.data.attributes.alternativeText || ''}
          width={props.imageMobile.data.attributes.width}
          height={props.imageMobile.data.attributes.height}
          style={{
            objectFit: 'contain',
            height: 'auto',
          }}
        />
      </Box>
      <Box px={2} py={2}>
        <Form
          phoneNumberLabel={props.phoneNumberLabel}
          buttonText={props.buttonText}
        />
      </Box>
    </Box>
  );
};
