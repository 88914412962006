import React from 'react';
import { Box, CardHeader, Grid, Typography, useTheme } from '@mui/material';
import {
  CardHeaderValue,
  CardHeaderValueOptional,
} from '@/shared/ui/card-offer/types';
import { useDynamicFontSize } from '@/shared/hooks/use-dynamic-font';
import { IImage } from '@/shared/types';

interface CardOfferHeaderProps {
  amount?: CardHeaderValue;
  term?: CardHeaderValue;
  background?: IImage;
}

export const CardOfferHeader = (props: CardOfferHeaderProps) => {
  const { amount, term, background } = props;

  const theme = useTheme();
  const dynamicFZ = useDynamicFontSize();

  const getHeaderContent = (values: CardHeaderValueOptional[]) => {
    const nonEmptyValues: CardHeaderValue[] = values.filter(
      (val) => !!val?.value,
    ) as CardHeaderValue[];

    if (!nonEmptyValues.length) return null;

    const fz = dynamicFZ(
      new Array(
        Math.max(...nonEmptyValues.map((item) => item.value.length)),
      ).join('a'),
    );

    return (
      <Grid container spacing={2}>
        {nonEmptyValues.map((item, index) => (
          <Grid item xs={12 / nonEmptyValues.length} key={index}>
            <Typography
              variant="text8"
              textAlign={index === 0 ? 'left' : 'right'}
              sx={{
                opacity: 0.5,
              }}
            >
              {item.title}
            </Typography>
            <Typography
              variant="headline6"
              textAlign={index === 0 ? 'left' : 'right'}
              component="p"
              color="common.onPrimaryContainer"
              sx={{
                fontSize: fz,
              }}
            >
              {item.value}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <CardHeader
      sx={{
        background: `url(${background?.data?.attributes?.url}) no-repeat`,
        backgroundSize: 'cover',
      }}
      subheader={
        <Box
          sx={{
            color: theme.palette.common.black,
            zIndex: 20,
            position: 'relative',
          }}
        >
          {getHeaderContent([amount, term])}
        </Box>
      }
    />
  );
};
