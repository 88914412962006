import React from 'react';
import { Box } from '@mui/material';
import 'react-multi-carousel/lib/styles.css';

import Carousel from 'react-multi-carousel';

import { TBlockTestimonial } from '@/shared/api/homepage-api';
import {
  FeedbackCard,
  ResponsiveTypography,
  HorizontalSlider,
} from '@/shared/ui';

type BlockTestimonialProps = Pick<TBlockTestimonial, 'title' | 'items'>;

export const BlockTestimonial = (props: BlockTestimonialProps) => {
  const { title, items } = props;

  const carouselArrowSxProps = {
    position: 'absolute',
    height: '100%',
    width: '25%',
    cursor: 'pointer',
    transition: 'all .25s ease',
    bgcolor: 'neutral.100',
    opacity: 0,
    '&:hover': {
      opacity: 0.3,
    },
  };

  const carouselItem = (
    <Box mx={-2} display={{ xs: 'none', md: 'block' }}>
      <Carousel
        autoPlay
        centerMode
        containerClass="container"
        draggable
        focusOnSelect={false}
        infinite
        minimumTouchDrag={80}
        pauseOnHover
        responsive={{
          desktop: {
            breakpoint: {
              max: 1500,
              min: 1024,
            },
            items: 3,
          },
          xl: {
            breakpoint: {
              max: 5000,
              min: 1501,
            },
            items: 4,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        shouldResetAutoplay={false}
        showDots={false}
        slidesToSlide={1}
        swipeable
        arrows
        customLeftArrow={
          <Box
            sx={{
              ...carouselArrowSxProps,
              boxShadow: '20px 0px 20px white',
              left: 0,
            }}
          />
        }
        customRightArrow={
          <Box
            sx={{
              ...carouselArrowSxProps,
              boxShadow: '-20px 0px 20px white',
              right: 0,
            }}
          />
        }
      >
        {items?.map((item) => (
          <Box
            key={item.id}
            px={1.25}
            pb={5}
            sx={{
              height: '100%',
            }}
          >
            <FeedbackCard {...item} />
          </Box>
        ))}
      </Carousel>
    </Box>
  );

  const swiperItem = items ? (
    <Box
      display={{ xs: 'block', md: 'none' }}
      ml={2}
      sx={{ overflow: 'hidden' }}
    >
      <Box>
        <HorizontalSlider
          spacing={1.5}
          items={items?.map((item) => (
            <Box
              key={item.id}
              sx={{
                width: 290,
                height: '100%',
              }}
            >
              <FeedbackCard {...item} />
            </Box>
          ))}
        />
      </Box>
    </Box>
  ) : null;

  return (
    <Box
      sx={{
        my: {
          xs: 7.5,
          md: 10,
        },
        overflow: 'hidden',
      }}
    >
      <ResponsiveTypography
        component="h2"
        variantMap={{
          xs: 'headline5',
          md: 'headline3',
        }}
        sx={{
          mb: {
            xs: 2,
            md: 3,
          },
          ml: {
            xs: 2,
            md: 0,
          },
          textAlign: {
            md: 'center',
          },
        }}
      >
        {title}
      </ResponsiveTypography>
      {swiperItem}
      {carouselItem}
    </Box>
  );
};
