import { ApiInstance } from './instance';
import { IImage, TPageData } from '@/shared/types';

export interface IQuestion {
  id: string;
  question: string;
  answer: string;
}

export interface IFeature {
  id: number;
  text: string;
  image: IImage;
}

export interface IMobileAppSection {
  id: number;
  enabled: boolean;
  title: string;
  image: IImage;
  features: IFeature[];
}

export interface IPageFaqContentAttributes {
  title: string;
  mobileAppSection: IMobileAppSection;
  questions: IQuestion[];
}

export type TPageFAQContent = TPageData<IPageFaqContentAttributes>;

export class FAQApi extends ApiInstance {
  constructor(locale?: string) {
    super(locale);
  }

  getContent(): Promise<TPageFAQContent> {
    const query = {
      populate: [
        'mobileAppSection',
        'mobileAppSection.image',
        'mobileAppSection.features',
        'mobileAppSection.features.image',
        'questions',
        'seo',
        'seo.metaSocial',
        'seo.metaSocial.image',
        'seo.metaImage',
      ],
    };

    return this._SendWithQuery<TPageFAQContent>('/faq-page', query);
  }

  async getQuestions(): Promise<IQuestion[]> {
    return this._Send('/faq-page').then(
      (res: any) => res?.attributes?.questions || [],
    );
  }
}
