import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, Typography } from '@mui/material';
import Image from 'next/image';
import { NotStyledParser } from '@/shared/ui';
import { IImage } from '@/shared/types';
import { TBlockRequirementsV2 } from '@/shared/api/homepage-api';

interface RequirementsCardSmallLeftProps {
  icon: IImage;
  title: string;
  images: TBlockRequirementsV2['cardSmallLeft']['images'];
}

export const RequirementsCardSmallLeft = (
  props: RequirementsCardSmallLeftProps,
) => {
  const { title, icon, images } = props;

  const ref = useRef<HTMLDivElement>();

  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (ref.current?.clientWidth) {
      setWidth(ref.current?.clientWidth);
    }
  }, [ref]);

  return (
    <Card
      variant="animated"
      sx={{
        p: 3.75,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: {
          xs: 285,
          md: 'auto',
        },
      }}
    >
      <Box>
        <Image
          src={icon?.data?.attributes.url}
          alt={icon?.data?.attributes.alternativeText || ''}
          width={35}
          height={35}
        />
        <Typography variant="headline6" component="div" sx={{ mt: 2.5 }}>
          <NotStyledParser data={title} />
        </Typography>
      </Box>

      <Box
        ref={ref}
        sx={{
          height: 80,
          position: 'relative',
        }}
      >
        {images.data.map((image, index, array) => (
          <Box
            key={index}
            sx={{
              position: 'absolute',
              left: width ? ((width - 80) / (array.length - 1)) * index : 0,
            }}
          >
            <Image
              src={image.attributes.url}
              alt={image.attributes.alternativeText || ''}
              height={80}
              width={80}
            />
          </Box>
        ))}
      </Box>
    </Card>
  );
};
