import React from 'react';
import { Box, Typography } from '@mui/material';
import { TBlockFAQ } from '@/shared/api/homepage-api';
import { QuestionItem } from '@/components/home/block-faq/ui/question-item';
import { SectionContainer } from '@/shared/ui';

type BlockFaqProps = Pick<TBlockFAQ, 'title' | 'items'>;

export const BlockFaq = (props: BlockFaqProps) => {
  const { items, title } = props;

  return (
    <SectionContainer>
      <Box
        display={{
          xs: 'block',
          md: 'none',
        }}
        mb={2.5}
      >
        <Typography variant="headline5" textAlign="center">
          {title}
        </Typography>
      </Box>
      <Box
        display={{
          xs: 'none',
          md: 'block',
        }}
        mb={2.5}
      >
        <Typography variant="headline3" textAlign="center">
          {title}
        </Typography>
      </Box>
      {items.map((item) => (
        <QuestionItem
          key={item.id}
          question={item.question}
          answer={item.answer}
        />
      ))}
    </SectionContainer>
  );
};
