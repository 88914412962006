import React from 'react';
import { TPageDataContent } from '@/shared/api/homepage-api';
import { BlockPartners, PopupBanner } from '@/shared/ui';
import { handleActionButtonClick } from '@/utils';
import {
  BlockNumbers,
  HomeBlockMain,
  BlockTestimonial,
  HomeBlockSteps,
  MobileAppBannerOuter,
  BlockExample,
  HomeBlockOffers,
  HomeBlockRequirements,
  BlockPayingMadeEasy,
  BlockFaq,
  BlockRequirementsV2,
  DownloadAppBanner,
  BlockStepsV2,
  AmmanaTargetOffer,
  SimpleBanner,
  AmmanaStats,
  AmmanaTargetOffer_V2,
  BlockExampleWithLink,
  BlockVideoFeedback,
  BlockCreditLimit,
} from '@/components/home';
import {
  PresentationBannerCeyloan,
  PresentationBannerTambadana,
} from '@/components/common';
import { ComponentType } from '@/shared/types';
import {
  FirstLoanCalculatorOuter,
  FirstLoanCalculatorOuter_v2,
} from '@/entities';

interface PageMapProps {
  content: TPageDataContent;
}

export const PageMap = (props: PageMapProps) => {
  const { content } = props;

  const componentsMap: Record<ComponentType, React.ReactElement> = {
    [ComponentType.Jumbotron]: (
      <HomeBlockMain
        title={content.title}
        subtitle={content.subtitle}
        text={content.text}
        buttonText={content.buttonText}
        image_desktop={content.image_desktop}
        image_mobile={content.image_mobile}
        onClick={handleActionButtonClick}
      />
    ),
    [ComponentType.Partners__shared]: (
      <BlockPartners
        title={content.title}
        partnersList={content?.partners?.data}
      />
    ),
    [ComponentType.Partners]: (
      <BlockPartners
        title={content.title}
        partnersList={content?.partners?.data}
      />
    ),
    [ComponentType.Steps]: (
      <HomeBlockSteps
        title={content.title}
        subtitle={content.subtitle}
        steps={content.steps}
        onClick={handleActionButtonClick}
      />
    ),
    [ComponentType.Steps_V2]: (
      <BlockStepsV2
        title={content.title}
        step_1={content.step_1}
        step_2={content.step_2}
        step_3={content.step_3}
        step_4={content.step_4}
      />
    ),
    [ComponentType.LoanExample]: (
      <BlockExample
        title={content.title}
        buttonText={content.buttonText}
        descriptionTitle={content.descriptionTitle}
        descriptionContent={content.descriptionContent}
      />
    ),
    [ComponentType.Offers]: (
      <HomeBlockOffers
        title={content.title}
        offers={content.offers}
        onClick={handleActionButtonClick}
      />
    ),
    [ComponentType.Requirements]: (
      <HomeBlockRequirements
        title={content.title}
        requirements={content.requirements}
        buttonText={content.buttonText}
        onClick={handleActionButtonClick}
      />
    ),
    [ComponentType.MobileAppBanner__shared]: (
      <MobileAppBannerOuter
        title={content.title}
        features={content.features}
        imageAlt={content.image?.data?.attributes?.alternativeText || ''}
        imageUrl={content.image?.data?.attributes?.url}
      />
    ),
    [ComponentType.MobileAppBanner]: (
      <MobileAppBannerOuter
        title={content.title}
        features={content.features}
        imageAlt={content.image?.data?.attributes?.alternativeText || ''}
        imageUrl={content.image?.data?.attributes?.url}
      />
    ),
    [ComponentType.PresentationBanner]: (
      <PresentationBannerCeyloan
        buttonText={content.buttonText}
        title={content.title}
        description={content.description}
        logo={content.logo}
        imageDesktop={content.imageDesktop}
        imageMobile={content.imageMobile}
      />
    ),
    [ComponentType.PresentationBannerTambadana]: (
      <PresentationBannerTambadana
        buttonText={content.buttonText}
        title={content.title}
        description={content.description}
        logo={content.logo}
        imageDesktop={content.imageDesktop}
        imageMobile={content.imageMobile}
      />
    ),
    [ComponentType.FirstLoanCalculator]: (
      <FirstLoanCalculatorOuter
        title={content.title}
        subtitle={content.subtitle}
        imageMobile={content.imageMobile}
        imageDesktop={content.imageDesktop}
        calculator={content.calculator}
      />
    ),
    [ComponentType.FirstLoanCalculator_V2]: (
      <FirstLoanCalculatorOuter_v2
        calculator={content.calculator}
        title={content.title}
        subtitle={content.subtitle}
        title_mobile={content.title_mobile}
        subtitle_mobile={content.subtitle_mobile}
        backgroundDesktop={content.backgroundDesktop}
        imageMobile={content.imageMobile}
        imageDesktop={content.imageDesktop}
      />
    ),
    [ComponentType.PayingMadeEasy]: (
      <BlockPayingMadeEasy
        title={content.title}
        chips={content.chips}
        imageDesktop={content.imageDesktop}
        imageMobile={content.imageMobile}
      />
    ),
    [ComponentType.PopupBanner]: (
      <PopupBanner
        imageDesktopUrl={content?.imageDesktop?.data?.attributes?.url}
        imageUrl={content?.image?.data?.attributes?.url}
      />
    ),
    [ComponentType.NumbersBlock]: (
      <BlockNumbers title={content.title} numbers={content.numbers} />
    ),
    [ComponentType.Testimonials]: (
      <BlockTestimonial title={content.title} items={content.items} />
    ),
    [ComponentType.FAQ]: (
      <BlockFaq title={content.title} items={content.items} />
    ),
    [ComponentType.Requirements_V2]: (
      <BlockRequirementsV2
        title={content.title}
        cardLarge={content.cardLarge}
        cardLargeChips={content.cardLargeChips}
        cardSmallLeft={content.cardSmallLeft}
        cardSmallRight={content.cardSmallRight}
        cardLargeChipsMobile={content.cardLargeChipsMobile}
      />
    ),
    [ComponentType.MobileAppBanner__shared_V2]: (
      <DownloadAppBanner
        title={content.title}
        image={content.image}
        qrCode={content.qrCode}
        googlePlayBtn={content.googlePlayBtn}
        listItems={content.listItems}
        listStyleImage={content.listStyleImage}
      />
    ),
    [ComponentType.AmmanaTargetOffer]: (
      <AmmanaTargetOffer
        title={content.title}
        imageDesktop={content.imageDesktop}
        imageMobile={content.imageMobile}
        qrCode={content.qrCode}
        text={content.text}
        googlePlayLink={content.googlePlayLink}
      />
    ),
    [ComponentType.AmmanaTargetOffer_V2]: (
      <AmmanaTargetOffer_V2
        titleMobile={content.titleMobile}
        titleDesktop={content.titleDesktop}
        imageDesktop={content.imageDesktop}
        imageMobile={content.imageMobile}
        imageFootnoteMobile={content.imageFootnoteMobile}
        buttonText={content.buttonText}
        text={content.text}
        googlePlayLink={content.googlePlayLink}
      />
    ),
    [ComponentType.AmmanaStats]: (
      <AmmanaStats title={content.title} numbers={content.rows} />
    ),
    [ComponentType.SimpleBanner]: (
      <SimpleBanner
        title={content.title}
        text={content.text}
        imageMobile={content.imageMobile}
        imageDesktop={content.imageDesktop}
        buttonText={content.buttonText}
      />
    ),
    [ComponentType.ABCalculator]: (
      <FirstLoanCalculatorOuter_v2
        calculator={content.calculator}
        title={content.title}
        subtitle={content.subtitle}
        title_mobile={content.title_mobile}
        subtitle_mobile={content.subtitle_mobile}
        backgroundDesktop={content.backgroundDesktop}
        imageMobile={content.imageMobile}
        imageDesktop={content.imageDesktop}
      />
    ),
    [ComponentType.ExampleWithLink]: (
      <BlockExampleWithLink
        link={content.link}
        text={content.text}
        buttonText={content.buttonText}
      />
    ),
    [ComponentType.VideoFeedback]: (
      <BlockVideoFeedback
        title={content.title}
        youtubeVideo={content.youtubeVideo}
      />
    ),
    [ComponentType.CreditLimitSlider]: <BlockCreditLimit card={content.card} />,
  };

  if (content?.enabled === false) {
    return null;
  }

  return componentsMap[content.__component];
};
