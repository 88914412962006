import React from 'react';
import {
  IconClose,
  Parser,
  ResponsiveTypography,
  SectionOuter,
} from '@/shared/ui';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { TBlockExample } from '@/shared/api/homepage-api';
import { useBoolean } from '@/shared/hooks';

type BlockExampleProps = Pick<
  TBlockExample,
  'descriptionTitle' | 'title' | 'buttonText' | 'descriptionContent'
>;

export const BlockExample = (props: BlockExampleProps) => {
  const { title, descriptionTitle, buttonText, descriptionContent } = props;

  const isOpen = useBoolean(false);

  const unexpandedContent = (
    <Box
      p={3.75}
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <ResponsiveTypography
        component="h2"
        variantMap={{
          xs: 'headline5',
          md: 'headline3',
        }}
        textAlign={{ sm: 'center' }}
        color="common.onPrimaryContainer"
        sx={{
          mb: {
            xs: 3.75,
            md: 0,
          },
        }}
      >
        {title}
      </ResponsiveTypography>
      <Button
        onClick={isOpen.on}
        sx={{
          minWidth: {
            xs: '100%',
            sm: 250,
          },
          height: 43,
          px: {
            sm: 5,
          },
        }}
      >
        {buttonText}
      </Button>
    </Box>
  );

  const expandedContent = (
    <Box p={{ xs: 3, md: 4 }}>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Typography
          sx={{
            mx: 4,
          }}
          variant="headline6"
          textAlign="center"
        >
          {descriptionTitle}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={isOpen.off}
          sx={{
            position: 'absolute',
            right: {
              xs: -20,
              md: 8,
            },
            top: {
              xs: -20,
              md: -3,
            },
          }}
        >
          <IconClose sx={{ fontSize: '14px' }} />
        </IconButton>
      </Box>

      <Box mt={2}>
        <Parser data={descriptionContent} />
      </Box>
    </Box>
  );

  return (
    <SectionOuter
      disablePaddings
      sx={{
        bgcolor: 'neutral.95',
      }}
    >
      {isOpen.value ? expandedContent : unexpandedContent}
    </SectionOuter>
  );
};
