import React from 'react';
import { Card, CardProps, useTheme } from '@mui/material';

export const CardOffer = (props: CardProps) => {
  const { children } = props;

  const theme = useTheme();

  return (
    <Card
      elevation={0}
      {...props}
      sx={{
        borderRadius: 2,
        boxShadow: theme.shadows[1],
        bgcolor: 'neutral.100',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...props.sx,
      }}
    >
      {children}
    </Card>
  );
};
