import React from 'react';
import { ResponsiveTypography, SectionOuter } from '@/shared/ui';
import { Box, Button } from '@mui/material';
import { TBlockExampleWithLink } from '@/shared/api/homepage-api';

type BlockExampleProps = Pick<
  TBlockExampleWithLink,
  'buttonText' | 'text' | 'link'
>;

export const BlockExampleWithLink = (props: BlockExampleProps) => {
  const { buttonText, text, link } = props;

  return (
    <SectionOuter
      disablePaddings
      sx={{
        bgcolor: 'neutral.95',
      }}
    >
      <Box
        p={3.75}
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <ResponsiveTypography
          component="h2"
          variantMap={{
            xs: 'headline5',
            md: 'headline3',
          }}
          textAlign={{ sm: 'center' }}
          color="common.onPrimaryContainer"
          sx={{
            mb: {
              xs: 3.75,
              md: 0,
            },
          }}
        >
          {text}
        </ResponsiveTypography>
        <Button
          href={link}
          target="_blank"
          size="small"
          sx={{
            minWidth: {
              xs: '100%',
              sm: 250,
            },
          }}
        >
          {buttonText}
        </Button>
      </Box>
    </SectionOuter>
  );
};
