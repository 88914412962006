export interface IAmmanaStatistic {
  disbursed_campaign: {
    last_year: number;
    only_last_year: number;
    only_this_year: number;
    this_year: number;
  };
  npl90: string;
  tkb90: string;
  total_customer: number;
  total_investor: number;
}

export class AmmanaApi {
  async getStatistic(): Promise<IAmmanaStatistic> {
    return new Promise((resolve, reject) => {
      fetch('https://api.ammana.id/v3/dashboards/statistics')
        .then((res) => res.json())
        .then(({ data }) => resolve(data))
        .then((res: any) => res?.attributes?.questions || [])
        .catch(reject);
    });
  }
}
