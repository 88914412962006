import { ApiInstance } from '@/shared/api/instance';
import { IPaymentGuide } from '@/shared/api/repayment-guide-api';
import { TSEOComponentData } from '@/shared/types';

export interface RepaymentRequisitesModalData {
  id: number;
  title: string;
  content: string;
}

export interface IRepaymentPageData {
  id: number;
  attributes?: {
    seo?: TSEOComponentData;
    default_payment_guide?: {
      data?: IPaymentGuide;
    };
    important_info: {
      id: number;
      description: string;
    };
    loan_agreement_number_modal: RepaymentRequisitesModalData;
    chooseServiceProviderSidebar: string;
    instructionsSidebarTitle: string;
    title: string;
  };
}

export class RepaymentApi extends ApiInstance {
  constructor(locale?: string) {
    super(locale);
  }

  getRepaymentPageData() {
    const query = {
      populate: [
        'payment_guide',
        'default_payment_guide',
        'loan_agreement_number_modal',
        'important_info',
        'seo',
        'seo.metaSocial',
        'seo.metaSocial.image',
        'seo.metaImage',
      ],
    };

    return this._SendWithQuery<IRepaymentPageData>('/repayment', query);
  }
}
