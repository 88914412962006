import React from 'react';
import { Box, Chip, Grid, Typography } from '@mui/material';
import { SectionOuter } from '@/shared/ui';
import { THomeBlockPayingMadeEasy } from '@/shared/api/homepage-api';

type BlockPayingMadeEasyProps = Pick<
  THomeBlockPayingMadeEasy,
  'title' | 'chips' | 'imageDesktop' | 'imageMobile'
>;

export const BlockPayingMadeEasy = (props: BlockPayingMadeEasyProps) => {
  const { title, chips, imageMobile, imageDesktop } = props;

  return (
    <SectionOuter
      disablePaddings
      sx={{
        my: {
          xs: 7.5,
          md: 10,
        },
      }}
    >
      <Grid container>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              m: 3.75,
              textAlign: {
                xs: 'center',
                md: 'left',
              },
            }}
          >
            <Box
              display={{
                xs: 'none',
                md: 'block',
              }}
              mb={12}
            >
              <Typography variant="headline3" component="h2">
                {title}
              </Typography>
            </Box>
            <Box
              display={{
                xs: 'block',
                md: 'none',
              }}
              mb={2.75}
            >
              <Typography variant="headline5" component="h2">
                {title}
              </Typography>
            </Box>
            <Grid
              container
              spacing={0.75}
              display={{
                xs: 'none',
                md: 'flex',
              }}
            >
              {chips?.map((item) => (
                <Grid item xs="auto" key={item.id}>
                  <Chip label={item.label} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              height: {
                xs: 320,
                sm: 180,
                md: '100%',
              },
              backgroundImage: {
                xs: `url(${imageMobile?.data?.attributes?.url})`,
                sm: `url(${imageDesktop?.data?.attributes?.url})`,
              },
              backgroundRepeat: 'no-repeat',
              backgroundPosition: {
                xs: 'top center',
                sm: 'top right',
                md: 'bottom left',
              },
              backgroundSize: 'cover',
            }}
          />
        </Grid>
      </Grid>
    </SectionOuter>
  );
};
