import React from 'react';
import { CreditLimitSlider } from '@/shared/api/homepage-api';
import { Box } from '@mui/material';
import { BlockCreditLimitCard } from '@/components/home/credit-limit/ui/card';
import { CardMobile } from '@/components/home/credit-limit/ui/card-mobile';

type BlockCreditLimitProps = Pick<CreditLimitSlider, 'card'>;

export const BlockCreditLimit = (props: BlockCreditLimitProps) => {
  if (!props.card) return null;

  const { card } = props;

  return (
    <>
      <Box>
        <Box
          display={{
            xs: 'none',
            md: 'block',
          }}
        >
          <BlockCreditLimitCard
            phoneNumberLabel={card.phoneNumberLabel}
            title={card.title}
            description={card.description}
            buttonText={card.buttonText}
            imageMobile={card.imageMobile}
            imageDesktop={card.imageDesktop}
          />
        </Box>
        <Box
          display={{
            md: 'none',
          }}
        >
          <CardMobile
            phoneNumberLabel={card.phoneNumberLabel}
            title={card.title}
            description={card.description}
            buttonText={card.buttonText}
            imageMobile={card.imageMobile}
            imageDesktop={card.imageDesktop}
          />
        </Box>
      </Box>
    </>
  );
};
