import React from 'react';
import { useTranslations } from 'use-intl';
import { Box, Stack, Typography, Paper, Grid, Button } from '@mui/material';

import { ResponsiveTypography, SectionOuter } from '@/shared/ui';
import { IImage } from '@/shared/types';

interface IRequirementCard {
  id: number;
  text: string;
  image: IImage;
}

interface IHomeBlockRequirementsProps {
  title: string;
  requirements: IRequirementCard[];
  onClick: () => void;
  buttonText: string;
}

export const HomeBlockRequirements = (props: IHomeBlockRequirementsProps) => {
  const { title, requirements, onClick } = props;

  const t = useTranslations();

  return (
    <SectionOuter>
      <Box
        pb={{
          xs: 3,
          md: 4,
        }}
      >
        <ResponsiveTypography
          variantMap={{
            xs: 'headline6',
            md: 'headline3',
          }}
          color="common.onPrimaryContainer"
          sx={{
            textAlign: { xs: 'left', md: 'center' },
          }}
        >
          {title}
        </ResponsiveTypography>
      </Box>
      <Grid container spacing={2} alignItems="stretch">
        {requirements?.map((rt) => (
          <Grid item xs={12} md={4} key={rt.id}>
            <Paper
              elevation={0}
              sx={{
                bgcolor: {
                  md: 'neutral.100',
                },
                height: '100%',
                borderRadius: 2,
                boxShadow: 'none',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box p={{ md: 3 }}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    sx={{
                      borderRadius: 1,
                      height: 44,
                      flex: '0 0 44px',
                      background: `url(${rt?.image?.data?.attributes?.url}) no-repeat center center`,
                      backgroundSize: {
                        xs: 25,
                        md: 34,
                      },
                    }}
                  />
                  <Box>
                    <Typography variant="text6" color="neutral.20">
                      {rt.text}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      {props.buttonText ? (
        <Box
          mt={2}
          display={{
            xs: 'block',
            md: 'none',
          }}
        >
          <Stack direction="row" justifyContent="center">
            <Button onClick={onClick} fullWidth>
              {props.buttonText}
            </Button>
          </Stack>
        </Box>
      ) : null}
    </SectionOuter>
  );
};
